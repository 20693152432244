import Image from 'next/legacy/image'
import cx from 'classnames'

import { Text } from '@cinch-labs/design-system'
import { Env, readFromEnv } from '@cinch-nx/environments'

import FooterLinksList from './footer-links-list/footer-links-list'
import SocialLinkList from './social-link-list/social-link-list'
import SocialMediaLinkList from './social-media-link-list/social-media-link-list'

import { footerLinks, socialLinks, socialMediaLinks } from './data'

import styles from './shared-ui-footer.module.css'

export function SharedUiFooter({ showFooterBorderRadius = true }) {
  return (
    <footer
      className={cx(styles.footer, {
        [styles.noBorderRadius]: !showFooterBorderRadius,
      })}
      id="main-footer"
    >
      <div className={styles.footerContainer}>
        <Text
          element="h2"
          className={styles.heading}
          fontSize="m"
          lineHeight="body"
          fontWeight="bold"
        >
          Need a hand?
          <Text
            element="span"
            className={styles.subHeading}
            fontSize="s"
            lineHeight="body"
          >
            Call, email or WhatsApp, we’re here to help.
          </Text>
        </Text>

        <SocialLinkList links={socialLinks} />

        <div className={styles.appStoreButtons}>
          <a
            href={readFromEnv(Env.IOSAppStoreUrl)}
            aria-label="app store badge"
          >
            <Image
              src={
                'https://a.storyblok.com/f/132543/384x114/7266ad02b3/download-app-store.png'
              }
              height={48}
              width={164}
              alt="Download on the app store"
            />
          </a>
          <a
            href={readFromEnv(Env.AndroidAppStoreUrl)}
            aria-label="google play badge"
          >
            <Image
              src={
                'https://a.storyblok.com/f/132543/564x168/9d227fc037/google-play-badge1.png'
              }
              height={48}
              width={164}
              alt="Get it on google play"
            />
          </a>
        </div>

        <Text
          element="p"
          className={styles.body}
          fontSize="s"
          fontWeight="light"
          lineHeight="body"
        >
          Cinch Cars Limited is an appointed representative of ITC Compliance
          Limited which is authorised and regulated by the Financial Conduct
          Authority (their registration number is 313486). Permitted activities
          include advising on and arranging general insurance contracts and
          acting as a credit broker not a lender.
        </Text>
        <Text
          element="p"
          className={styles.body}
          fontSize="s"
          fontWeight="light"
          lineHeight="body"
        >
          We can introduce you to a limited number of finance providers. We do
          not charge fees for our Consumer Credit services. We typically receive
          a payment(s) or other benefits from finance providers should you
          decide to enter into an agreement with them, typically either a fixed
          fee or a fixed percentage of the amount you borrow. The payment we
          receive may vary between finance providers and product types. The
          payment received does not impact the finance rate offered.
        </Text>
        <Text
          element="p"
          className={styles.body}
          fontSize="s"
          fontWeight="light"
          lineHeight="body"
        >
          All finance applications are subject to status, terms and conditions
          apply, UK residents only, 18’s or over. Guarantees may be required.
        </Text>

        <FooterLinksList links={footerLinks} />

        <SocialMediaLinkList links={socialMediaLinks} />
      </div>
    </footer>
  )
}

export default SharedUiFooter
