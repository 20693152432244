import { IEnvironmentFromFile } from './environment.types'

export const environmentProd: IEnvironmentFromFile = {
  ADOBE_DTM: 'launch-EN4b0cc1c046db4a3da7308feed4637416.min',
  IDS_AUTH_KEY: 'oxford-user:https://identity-production.cinch.co.uk:fe',
  AUTH0_AUTH_KEY:
    'oxford-user:https://auth.cinch.co.uk:B5XFE1dV7b8IMpeZxY8kqCPOPiAwgwD7',
  CINCHCARE_SERVICE_URL: 'https://cinchcare.cnv-prod.aws.cinch.co.uk',
  PERMISSION_URL: 'https://permissions.snc-prod.aws.cinch.co.uk/unsubscribe',
  DELIVERY_SERVICE_URL: 'https://delivery.api.cinch.co.uk',
  ORDER_SERVICE_URL: 'https://orders.api.cinch.co.uk',
  PROFILE_SERVICE_URL: 'https://profile.onr-prod.aws.cinch.co.uk',
  SEARCH_SERVICE_URL: 'https://search-api.snc-prod.aws.cinch.co.uk',
  SEARCH_VALUE_PROPOSITIONS: 'true',
  SEGMENT_KEY: 'fxuC9PcNxIbVcTp5QHTWYF9QggSg3Mvs',
  STATIC_ASSETS_URL: 'https://assets-production.cinch.co.uk',
  IDENTITY_SERVICE_URL: 'https://api-production.cinch.co.uk/idt/v1',
  AUTH0_SERVICE_URL: 'https://auth.cinch.co.uk',
  AUTH0_DATABASE_NAME: 'Username-Password-Authentication',
  AUTH0_CLIENT_ID: 'B5XFE1dV7b8IMpeZxY8kqCPOPiAwgwD7',
  PROMO_BANNER_NAME: 'sale',
  CINCH_URL: 'https://www.cinch.co.uk',
  CINCH_APP_URL: 'https://mobile.snc-prod.aws.cinch.co.uk',
  TRUSTPILOT_URL: 'https://trustpilot.snc-prod.aws.cinch.co.uk',
  STORYBLOK_BFF_COMPONENT: 'https://storyblok-bff.snc-prod.aws.cinch.co.uk',
  CONSUMER_VALUATIONS_SERVICE_URL:
    'https://consumer-valuations.cnv-prod.aws.cinch.co.uk',
  CINCH_CHARGE_CARD_REQUEST_URL:
    'https://extras-cinch-charge-card.cnv-prod.aws.cinch.co.uk',
  CINCH_CHARGE_CARD_REQUEST_API_KEY: 'R48mFOTC6BamHbg1LR8k97fvGXOBOweg1PsHdERV',
  PRODUCT_DETAIL_SERVICE_URL: 'https://product.api.cinch.co.uk',
  RESERVATION_STATUS_URL: 'https://orders.api.cinch.co.uk/reservation-status',
  INVENTORY_SERVICE_HISTORY_URL:
    'https://service-history.api.cinch.co.uk/basic',
  IOS_APP_STORE_URL:
    'https://apps.apple.com/gb/app/cinch-cars-without-the-faff/id1641060889',
  ANDROID_APP_STORE_URL:
    'https://play.google.com/store/apps/details?id=com.cinchapp',
  ADMIN_FEE_RETURN_TO_CHECKOUT: 'true',
  ADMIN_FEE_VALUE: '9900',
  ADMIN_FEE_LOYALTY: 'true',
  UNSUBSCRIBE_SERVICE_URL:
    'https://communication-v2-user-prefs.api.cinch.co.uk/unsubscribe',
  UNSUBSCRIBE_MINIMUM_DURATION: '3000',
  CHECKOUT_RESERVATIONS_DECOUPLING: 'true',
  CHECKOUT_RESERVATIONS_DECOUPLING_PHASE_TWO: 'false',
  RESERVATION_SERVICE_URL:
    'https://reservations.cnv-prod.aws.cinch.co.uk/source/orders',
  DELIVERY_SLOT_AVAILABILITY_SERVICE_URL:
    'https://delivery.api.cinch.co.uk/delivery-slots',
  LOGISTICS_FEES_SERVICE_URL: 'https://logistics-fees.snp-prod.aws.cinch.co.uk',
  FINANCE_APPLICATION_SERVICE_URL:
    'https://finance-application.api.cinch.co.uk',
  FINANCE_QUOTE_SERVICE_URL: 'https://finance-quote.pnf-prod.aws.cinch.co.uk',
  FINANCE_SOFT_SEARCH_URL_PATH: '/car-finance/check-eligibility',
  PAYMENT_LINK_SERVICE_URL: 'https://payment-link.cnv-prod.aws.cinch.co.uk',
  STRIPE_PUBLISHABLE_KEY: 'pk_live_3rDkEJzP58ZdTSIqCDS7XoAy00NjjZYQGR',
  GAP_SERVICE_URL: 'https://gap-insurance.cnv-prod.aws.cinch.co.uk',
  SERVICING_AND_WARRANTY_FLAG: 'false',
  DISABLE_CINCHCARE: 'false',
  GAP_INSURANCE_FLAG: 'false',
  CUSTOMER_DATA_API_KEY: '141271a4-f574-41de-9eee-962ee2674d80',
  CUSTOMER_DATA_API_URL: 'https://customer-data.api.cinch.co.uk',
  ENABLE_CINCH_APP_USER_AGENT_IN_USED_CARS_SSR: 'true',
  SEARCH_MULTIPLE_IMAGES: 'true',
  ENABLE_ZENDESK_SCRIPT: 'true',
  VEHICLE_CARD_V2: 'false',
  NEXT_BEST_ACTION: 'true',
  SEARCH_STORE_LOCATIONS: 'true',
  VEHICLE_OWNERSHIP_SERVICE_URL:
    'https://vehicle-ownership.onr-prod.aws.cinch.co.uk',
  ENABLE_ADMIN_FEE_COMPLIANCE: 'true',
  ENABLE_BUY_NOW_CTA_MESSAGING: 'true',
  ENABLE_EMAIL_QUOTE: 'true',
  HOMEPAGE_FAVOURITING: 'false',
  ENABLE_PRODUCT_DETAIL_PAGE_VARIANT: 'true',
  ENABLE_QUOTES_MARKETING: 'true',
  ENABLE_PX_ON_HOMEPAGE_HERO: 'false',
  SEARCH_FINANCE_TYPE_FILTER: 'true',
  SHOW_QUOTE_TERM_MONTHS: 'true',
  SHOW_SCORELESS_TRUSTPILOT: 'false',
  SOFT_SEARCH_CHANGES: 'true',
  SHOW_CINCH_COVER_IN_PROFILE: 'true',
  SHOW_MULTI_TERM_VAPS_IN_PROFILE: 'false',
  SHOW_TYRE_INSURANCE_IN_PROFILE: 'false',
  BANK_SERVICE_URL: 'https://bank-details.cnv-prod.aws.cinch.co.uk',
  DELIVERY_FEE_PENCE_STANDARD: '4900',
  DELIVERY_FEE_PENCE_EXPRESS: '9900',
  VAP_SERVICE_URL:
    'https://6gantc3nhf.execute-api.eu-west-1.amazonaws.com/prod',
  SEARCH_RECENTLY_VIEWED_VEHICLES: 'true',
  USE_REFRESH_TOKEN: 'true',
}
