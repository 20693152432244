export const TARGET_CLIENT_ERROR_MESSAGES = {
  TARGET_CLIENT_DECISIONING_ENGINE_NOT_READY:
    'The Target Client decisioning engine is not ready',
  FAILED_TO_FETCH_TARGET_ACTIVITIES: 'Failed to fetch all target activities',
  NO_TARGET_ACTIVITIES_FOUND:
    'No target activities found. Please check the activity location ID',
  TARGET_ACTIVITY_NOT_FOUND:
    'Target activity not found. Please check target activity ID',
  INIT_TIMEOUT: 'Timed out on initialising target client',
}
