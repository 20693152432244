import { SbBlokData } from '@cinch-nx/data-storyblok'
import { Wrapper } from '@cinch-nx/shared-ui'
import React from 'react'

import { ComponentMap } from './types'

export const hasComponent = (componentName: string, components: ComponentMap) =>
  !!components[componentName]

export const isValidBlock = (block: SbBlokData): block is SbBlokData =>
  !!block?.component && !!block?._uid

export const ElementFactory = (
  block: SbBlokData,
  componentMap: ComponentMap,
  props?: { [key: string]: unknown },
  componentsWithWrapper?: string[],
) => {
  if (!isValidBlock(block)) {
    if (block) {
      console.error(
        `Unable to render malformed block as it is missing the 'component' or '_uid' properties:`,
      )
      console.error(block)
    }
    return
  }

  // component does exist
  if (hasComponent(block.component, componentMap)) {
    if (
      Array.isArray(componentsWithWrapper) &&
      componentsWithWrapper.includes(block.component)
    ) {
      return (
        <Wrapper isSlim key={block._uid}>
          {React.createElement(componentMap[block.component], {
            ...block,
            ...props,
          })}
        </Wrapper>
      )
    }

    return React.createElement(componentMap[block.component], {
      ...block,
      ...props,
      key: block._uid,
    })
  }

  // component doesn't exist yet
  return React.createElement(
    () => (
      <div>The component `{block.component}` has not been created yet.</div>
    ),
    {
      key: block._uid,
    },
  )
}

export const checkNotOverLimit = (
  block: SbBlokData,
  limitedItems?: { [key: string]: number },
) =>
  !(
    limitedItems &&
    block.component &&
    Object.prototype.hasOwnProperty.call(limitedItems, block.component) &&
    (limitedItems[block.component]-- ?? 1) <= 0
  )
