import type { TargetClientOptions } from './types'

const isProd = process.env['STAGE_NAME'] === 'production'

const trackingServerDomain = isProd
  ? 'bcacinchprod.112.2o7.net'
  : 'bcaoxforddev.112.2o7.net'

export const CLIENT_TIMEOUT = 3000

export const targetClientOptions: TargetClientOptions = {
  client: 'bca',
  organizationId: 'E5F2C8A15481C0E20A4C98BC@AdobeOrg',
  timeout: CLIENT_TIMEOUT,
  serverDomain: trackingServerDomain,
  decisioningMethod: 'on-device',
  pollingInterval: 60_000,
}
